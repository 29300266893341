var $newsletterPopup = $('.newsletter-popup');
var $newsletterForm = $('.newsletter-form');
var $formContainer = $newsletterPopup.find('.form-container');
var $popupText = $newsletterPopup.find('p');
var $submitButton = $newsletterForm.find('button[type="submit"]');
var $successMessage = $newsletterPopup.find('.success-message');
var $flashError = $newsletterPopup.find('.flash-error');

var showNewsletterPopup = function() {
    $newsletterPopup.addClass('show');
};

var dismissNewsletterPopup = function() {
    $newsletterPopup.removeClass('show');
    axios.post('/v3/newsletter/dismiss');
};

$newsletterPopup.find('.close-button').click(dismissNewsletterPopup);

var showSpinner = function() {
    var html = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>';
    $submitButton.html(html);
};

var hideSpinner = function() {
    $submitButton.text('Subscribe');
}

var hideErrors = function() {
    $newsletterForm.removeClass('was-validated');
    $flashError.removeClass('show');
    $popupText.removeClass('hide');
};

var showErrors = function() {
    $popupText.addClass('hide');
    $newsletterForm.addClass('was-validated');
};

var showFlashError = function(errorMessage) {
    $popupText.addClass('hide');
    $flashError.text(errorMessage).addClass('show');
};

var showSuccessConfirmation = function() {
    $formContainer.addClass('hide');
    $successMessage.addClass('show');
    setTimeout(dismissNewsletterPopup, 1500);
}

var submitNewsletterForm = function() {
    var email = $newsletterForm.find('input[name="email"]').val().trim();

    const apiRequest = () => axios.post($newsletterForm[0].action, {email: email});

    const onSuccess = () => {
        showSuccessConfirmation();
    };

    const onError = ({error}) => {
        hideSpinner();

        if (error.response && error.response.data) {
            var errors = error.response.data.errors;

            if (errors && errors.email && errors.email.length > 0) {
                showFlashError(errors.email[0]);
                return;
            }
        }

        showFlashError('Sorry, there was an error.');
    };

    submitApiRequest({apiRequest, onSuccess, onError});

    return false;
};

$newsletterForm.submit(function(event) {
    hideErrors();

    if ($newsletterForm[0].checkValidity() === false) {
        showErrors();
        return false;
    }

    showSpinner();

    return submitNewsletterForm();
});

if (!newsletterDismissed) {
    setTimeout(function() {
        showNewsletterPopup();
    }, 5000);
}
