var $loginModal = $('.login-modal');
var $loginForm = $('.login-form');
var $loginModalForm = $loginModal.find('.login-form');
var $body = $('body');
var $flashMessage = $loginModal.find('.flash-message');

var openLoginModal = function() {
    $flashMessage.removeClass('show');
    $loginForm.find('input[type!="hidden"]').val('');
    
    window.modal.open($loginModal[0], () => {
        $body.addClass('no-scroll-login');
        $loginModal.find('.login-form input[name="instructor_code"]').focus();
    });
};

var closeLoginModal = function() {
    window.modal.close($loginModal[0], () => {
        $body.removeClass('no-scroll-login');
        $loginModalForm.removeClass('was-validated');
    });
};

$('.login-modal-trigger').click(function(event) {
    event.preventDefault();
    openLoginModal();
});

$loginModal.find('.desktop-close-button, .mobile-close-button').click(closeLoginModal);

$(document).keyup(function(event) {
    // Close when esc key is pressed
    if (event.keyCode === 27 && $loginModal.hasClass('show')) {
        closeLoginModal();
    }
});

$loginForm.submit(function(event) {
    var form = event.currentTarget;

    if (form.checkValidity() === false) {
        $(form).addClass('was-validated').removeClass('hide-feedback');
        return false;
    }

    var spinnerHtml = '<div class="spinner-border" role="status"><span class="visually-hidden">Loading...</span></div>';
    var $submitButton = $(form).find('button[type="submit"]');
    $submitButton.html(spinnerHtml);
    $submitButton[0].disabled = true;

    return submitLogin(form);
});
