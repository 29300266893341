const html = document.querySelector('html');
const body = document.querySelector('body');
const pageContent = document.getElementById('page-content');
const notificationBanner = document.querySelector('.notification-banner');

function enable() {
    const pageContentTop = pageContent.style.top
    const scrollY = pageContentTop ? 0 - pageContentTop.slice(0, -2) : 0;

    if (notificationBanner) {
        notificationBanner.classList.remove('d-none');
    }

    body.classList.remove('no-scroll');
    pageContent.style.top = 'auto';
    html.style.scrollBehavior = 'auto';
    window.scrollTo(0, scrollY);
    html.style.scrollBehavior = 'smooth' ;
}

function disable() {
    const scrollY = window.scrollY;

    if (notificationBanner) {
        notificationBanner.classList.add('d-none');
    }

    body.classList.add('no-scroll');
    pageContent.style.top = `${0 - scrollY}px`;
}

function isDisabled() {
    return pageContent.style.top !== '' && pageContent.style.top !== 'auto';
}

window.backgroundScroll = {enable, disable, isDisabled};
