function open(modalElement, afterOpen) {
    modalElement.classList.add('display');

    setTimeout(() => {
        modalElement.classList.add('show');
        window.backgroundScroll.disable();
        if (afterOpen) afterOpen();
    }, 10);
}

function close(modalElement, afterClose) {
    modalElement.classList.remove('show');
    window.backgroundScroll.enable();
    
    setTimeout(function() {
        modalElement.classList.remove('display');
        if (afterClose) afterClose();
    }, 500);
    
}

window.modal = {open, close};