const Clipboard = require('clipboard');

const previewElements = document.querySelectorAll('#preview-tab, #preview-pane');
const detailsElements = document.querySelectorAll('#details-tab, #details-pane');
const desktopCloseButton = document.querySelectorAll('#details-preview .desktop-close-button')[0];
const mobileCloseButton = document.querySelectorAll('#details-preview .mobile-close-button')[0];
const resource = document.querySelectorAll('#details-preview .resource')[0];
const toggles = [...resource.getElementsByClassName('select-toggle')];

const detailsPreview = document.getElementById('details-preview');
const detailsPane = document.getElementById('details-pane');
const previewPane = document.getElementById('preview-pane');
const thumbnail = document.getElementById('details-preview-thumbnail');
const title = document.getElementById('details-preview-title');
const linkColumn = document.getElementById('link-column');
const shareablePreviewLink = document.getElementById('shareable-preview-link');
const linkInput = document.getElementById('link-input');
const teacherEditionLinks = document.getElementsByClassName('teacher-edition-link');
const copyLinkButton = shareablePreviewLink.querySelector('.copy-link-button');
const clipboard = new Clipboard(copyLinkButton);
let clipboardSuccessTimeout;
const iframe = document.getElementById('details-preview-iframe');
const detailsIframe = document.getElementById('resource-details-iframe');

const shareablePreviewResourceId = detailsPreview.dataset.shareablePreviewResourceId;

if (shareablePreviewResourceId) {
    loadDetailsPreview(shareablePreviewResourceId);
}

$(document).on('click', '.resource-thumbnail, .show-preview-label', function(event) {
    const $resource = $(event.target).parents('.resource');
    const id = $resource.data('id');
    loadDetailsPreview(id);
});

function loadDetailsPreview(resourceId) {
    openDetailsPreview();

    const apiUrl = window.ltiGallery ?
        `/v3/lti/resource-gallery/${resourceId}/api`
        : `/v3/resource-gallery/${resourceId}/api`;
    const apiRequest = () => axios.get(apiUrl);

    const onSuccess = response => {
        initializeComponents(response.data.resources[0]);
    };

    const onAuthError = () => window.location.href = '/resource-gallery';

    const animations = {startLoadingAnimation};
    
    submitApiRequest({apiRequest, onSuccess, onAuthError, animations});
}

desktopCloseButton.addEventListener('click', closeDetailsPreview);
mobileCloseButton.addEventListener('click', closeDetailsPreview);

document.addEventListener('keyup', event => {
    // Close when esc key is pressed
    if (event.keyCode === 27 && detailsPreview.classList.contains('show')) {
        closeDetailsPreview();
    }
});

if (teacherEditionLinks) {
    teacherEditionLinks.forEach(teacherEditionLink => {
        teacherEditionLink.addEventListener('click', function(event) {
            event.stopPropagation();
        });        
    })
}

function openDetailsPreview() {
    clearDetailsPreviewData();
    previewElements.forEach(element => element.classList.remove('active'));
    detailsElements.forEach(element => element.classList.add('active'));
    
    window.modal.open(detailsPreview);
}

function applyIframeScrollSettings(element) {
    element.style.overflow = 'auto';
    element.style.height = '100%';
    element.style['-webkit-overflow-scrolling'] = 'touch';
}

iframe.onload = () => {
    if (iframe.src === 'about:blank') {
        return;
    }

    if (iframe.contentDocument !== null) {
        applyIframeScrollSettings(iframe.contentDocument.querySelector('html'));
        applyIframeScrollSettings(iframe.contentDocument.querySelector('body'));

        iframe.contentWindow.onbeforeunload = () => previewPane.classList.add('loading');

        // Workaround for iOS
        iframe.contentWindow.addEventListener('pagehide', () => previewPane.classList.add('loading'));
    }

    previewPane.classList.remove('loading');
};

detailsIframe.onload = () => {
    if (iframe.src === 'about:blank') {
        return;
    }

    detailsPane.classList.remove('loading');
};

function closeDetailsPreview() {
    window.modal.close(detailsPreview, clearDetailsPreviewData);
}

function startLoadingAnimation() {
    detailsPane.classList.add('loading');
    previewPane.classList.add('loading');
}

function stopLoadingAnimation() {
    detailsPane.classList.remove('loading');   
}

function initializeComponents(resourceData) {
    initializeDetailsPreviewData(resourceData);

    if (window.selections) {
        window.selections.initialize(toggles);
    }
}

function initializeDetailsPreviewData(data) {
    thumbnail.setAttribute('src', data.thumbnail);
    title.textContent = data.title;
    initializeShareablePreviewLink(data);
    displayPreviewPaneContent(data);
    detailsIframe.setAttribute('src', data.detailsURL);
    resource.dataset.id = data.id; 
}

function initializeShareablePreviewLink(data) {
    if (data.shareable_preview_url) {
        linkInput.value = data.shareable_preview_url;
        linkColumn.classList.remove('d-none');

        clipboard.on('success', function(event) {
            event.clearSelection();
            shareablePreviewLink.classList.add('copied');
            $(copyLinkButton).popover('show');

            clearTimeout(clipboardSuccessTimeout);
            clipboardSuccessTimeout = setTimeout(() => {
                $(copyLinkButton).popover('hide');
                shareablePreviewLink.classList.remove('copied');
            }, 2000);
        });
    } else {
        linkColumn.classList.add('d-none');
        linkInput.value = '';
    }
}

function displayPreviewPaneContent(data) {
    iframe.classList.remove('d-none');
    iframe.setAttribute('src', data.previewURL);
}

function clearDetailsPreviewData() {
    initializeDetailsPreviewData({
        thumbnail: '',
        title: '',
        previewURL: 'about:blank',
        detailsURL: 'about:blank'
    });
}
