require('./api-request');
require('./background-scroll');
require('./custom-modal');
require('./login');
require('./details-preview');
require('./newsletter-popup');
require('./resource-thumbnails');

import { Popover } from "bootstrap";

new Popover(document.querySelector('[data-bs-toggle="popover"]'));

var isScrolledIntoView = function (el) {
    var boundary = el.getBoundingClientRect();
    return boundary.top < window.innerHeight && boundary.bottom >= 0;
};

const loginContainer = document.getElementById('login-container');
const googleLoginForm = document.getElementById('google-login-form');
const googleIdTokenInput = document.getElementById('google-id-token');

var handleLoginVisibility = function () {
    var isVisible = isScrolledIntoView(loginContainer);
    var $navbarLogin = $('.navbar .instructor-login-container');
    
    if (isVisible) {
        $navbarLogin.hide();
    } else {
        $navbarLogin.show();
    }
};

if ($('#login-container').length) {
    // To-do: debounce scroll event
    $(window).scroll(handleLoginVisibility);
    handleLoginVisibility();
}

var scrollToLogin = function() {
    loginContainer.scrollIntoView({behavior: "smooth"});
    loginContainer.querySelector('input[name="instructor_code"]').focus();
};

$('.instructor-login-container .scroll-to-top').click(scrollToLogin);

$('.login-link').click(function(event) {
    event.preventDefault();
    scrollToLogin();
});

window.submitLogin = function(form) {
    return true;
};

window.handleGoogleCredentialResponse = function (response) {
    googleIdTokenInput.value = response.credential;
    googleLoginForm.submit();
};
