const errorModal = document.getElementById('error-modal');
const closeButton = errorModal.querySelector('.close-button');

closeButton.addEventListener('click', closeErrorModal);

window.submitApiRequest = function({
    apiRequest,
    onSuccess,
    onError,
    onAuthError,
    animations,
}) {
    if (!animations) animations = {};
    if (animations.startLoadingAnimation) animations.startLoadingAnimation();

    apiRequest()
        .then(response => {
            if (onSuccess) onSuccess(response);
            if (animations.stopLoadingAnimation) animations.stopLoadingAnimation();
        })
        .catch(error => {
            if (error.response && error.response.status === 401) {
                if (error.response.data.lti_timeout === true) {
                    window.location.href = '/v3/lti/timeout';
                } else if (onAuthError) {
                    onAuthError({error, openErrorModal});
                }

                return;
            }

            if (animations.stopLoadingAnimation) animations.stopLoadingAnimation();

            if (onError) {
                onError({error, openErrorModal});
                return;
            }

            openErrorModal();
        });
};

function openErrorModal() {
    modal.open(errorModal, () => {
        closeButton.focus();
    });
}

function closeErrorModal() {
    modal.close(errorModal);
}